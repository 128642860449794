<template>
  <!-- 素材库 -->
  <div class="materialLibrary">
    <!-- 主面板 -->
    <Panel title="我的素材" @close="handleClose">
      <!-- 面板头部 -->
      <div class="materialLibrary-header">
        <!-- 路由 -->
        <div class="materialLibrary-router">
          <router-link
            class="materialLibrary-router-item"
            v-for="item in librarys"
            :key="item.type"
            :to="item.url"
          >
            <div
              class="materialLibrary-router-item-wrap"
              :class="currentLibrary === item.type ? 'active' : ''"
              @click="currentLibrary = item.type"
            >
              <img
                :src="
                  currentLibrary === item.type ? item.activeIcon : item.icon
                "
                alt=""
                class="materialLibrary-router-item-wrap-img"
              />
              <p>{{ item.name }}</p>
            </div>
          </router-link>
        </div>
        <!-- 数据源 -->
        <el-radio-group v-model="dataSource">
          <el-radio-button
            v-for="item in dataSources"
            :key="item.id"
            :label="item.id"
            >{{ item.name }}</el-radio-button
          >
        </el-radio-group>
      </div>
      <router-view />
    </Panel>
  </div>
</template>

<script>
import Panel from 'c/basic/Panel';
export default {
  name: 'MaterialLibrary',
  components: {
    Panel
  },
  data() {
    return {
      // 当前素材库
      currentLibrary: '',
      // 素材库
      librarys: [
        {
          name: '图标库',
          type: 'iconLibrary',
          icon: require('a/workbench/materiaLibrary/icon.png'),
          activeIcon: require('a/workbench/materiaLibrary/icon-active.png'),
          url: '/workbench/map/materialLibrary/iconLibrary'
        },
        {
          name: '线段库',
          type: 'polylineLibrary',
          icon: require('a/workbench/materiaLibrary/polyline.png'),
          activeIcon: require('a/workbench/materiaLibrary/polyline-active.png'),
          url: '/workbench/map/materialLibrary/polylineLibrary'
        },
        {
          name: '多边形库',
          type: 'planeLibrary',
          icon: require('a/workbench/materiaLibrary/image.png'),
          activeIcon: require('a/workbench/materiaLibrary/image-active.png'),
          url: '/workbench/map/materialLibrary/planeLibrary'
        },
        // {
        //   name: '图片库',
        //   type: 'imageLibrary',
        //   icon: require('a/workbench/materiaLibrary/image.png'),
        //   activeIcon: require('a/workbench/materiaLibrary/image-active.png'),
        //   url: '/workbench/map/materialLibrary/imageLibrary'
        // },
        {
          name: '样式库',
          type: 'styleLibrary',
          icon: require('a/workbench/materiaLibrary/style.png'),
          activeIcon: require('a/workbench/materiaLibrary/style-active.png'),
          url: '/workbench/map/materialLibrary/styleLibrary'
        }
      ],
      // 当前数据来源
      dataSource: 1,
      // 数据来源
      dataSources: [
        { name: '系统', id: 1 },
        { name: '我的', id: 2 },
        { name: '收藏', id: 3 }
        // { name: '最近使用', id: 4 }
      ]
    };
  },
  created() {
    // 根据路由匹配当前选中素材库
    this.currentLibrary = location.hash.split('/').pop();
    // 获取工作空间id
    this.getOptionsData();
  },
  methods: {
    // 关闭
    handleClose() {
      this.$router.push('/workbench/map');
    },
    // 获取工作空间id
    getOptionsData() {
      if (!this.$store.state.common.workspaceId) {
        this.$service.workspace.workspaceGetAll().then(res => {
          if (res.data.status === 200) {
            this.$store.state.common.workspaceId = res.data.data[0].id;
          }
        });
      }
    }
  },

  watch: {
    // 监听当前素材库
    currentLibrary: {
      immediate: true,
      handler(data) {
        this.$store.commit('materialLibrary/setCurrentLibraryData', data);
      }
    },
    // 监听数据来源
    dataSource: {
      immediate: true,
      handler(data) {
        this.$store.commit('materialLibrary/setDataSourceId', data);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.materialLibrary {
  &-header {
    padding: 10px 20px 20px;
    border-bottom: 1px solid #d2d2d2;
  }
  // 素材库路由
  &-router {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    &-item {
      &-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        width: 65px;
        height: 92px;
        padding: 10px 0;
        border: 1px solid #e5e5e5;
        border-radius: 12px;
        font-size: 14px;
        color: #666;
        &-img {
          width: 48px;
        }
        &.active {
          border-color: #507cff;
          color: #507cff;
          font-weight: bold;
          background: #f2f5ff;
        }
      }
    }
  }
  /deep/ .el-radio {
    &-group {
      display: flex;
      align-items: center;
      border-radius: 4px 0px 0px 4px;
    }
    &-button {
      flex: 1;
      height: 34px;
      &.is-active {
        .el-radio-button__inner {
          color: #fff;
          border-color: #507cff;
          background: #507cff;
        }
      }
      &__inner {
        width: 100%;
        line-height: 34px;
        padding: 0;
        font-size: 14px;
      }
    }
  }
}
</style>
